import React, { ReactNode, useEffect, useState } from 'react';
import styles from '../../assets/styles/pages/Reports.module.scss';
import PageHeader from '../../components/PageHeader';
import {
  AdLead,
  Brand,
  ClientTetherSalesCycleReport,
  ClientTetherSalesCycleReportData,
} from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchSalesCycleReport,
  fetchSalesCycleReportByAds,
  syncClientTetherClients,
} from '../../services/clientTether';
import ClientTetherSalesCycleReportTable from '../../components/Tables/Pipelinez/ClientTetherSalesCycleReportTable';
import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import { DrawerMenu } from '../../components/Menu';
import LeadDetails from '../../components/Details/LeadDetails';
import { PrimaryButton } from '../../components/Buttons';
import GroupAddOutlinedIcon from '@mui/icons-material/GroupAddOutlined';
import AttachMoneyOutlinedIcon from '@mui/icons-material/AttachMoneyOutlined';
import CurrencyExchangeOutlinedIcon from '@mui/icons-material/CurrencyExchangeOutlined';
import { TbCreditCardPay } from 'react-icons/tb';
import DashboardItem from '../../components/Cards/Billing/DashboardItem';
import { BsGraphUpArrow } from 'react-icons/bs';
import FacebookAdDetails from '../../components/Details/Ads/FacebookAdDetails';
import CircularLoading from '../../components/CircularLoading';
import { PiHandCoins } from 'react-icons/pi';
import { editLeads } from '../../services/lead';
import { errorMessageParser } from '../../utils/helpers/ToastHelper';
import { toggleAlert } from '../../redux/actions';
import { MdSync } from 'react-icons/md';
import { CrmSetting } from '../../types/ICrm';
import { getCrmSettingsByBrand } from '../../services/crm';

interface PipelinezDashboardItem {
  value: number;
  label: string;
  isCurrency: boolean;
  isPercentage: boolean;
  icon: ReactNode;
}

const Pipelinez: React.FC = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const campaignProvider = useSelector(
    (state: any) => state?.campaignProvider?.campaignProvider,
  );
  const xsOnly = useMediaQuery(theme.breakpoints.only('xs'));
  const brand: Brand = useSelector((state: any) => state?.brand?.brand);
  const location: Brand = useSelector(
    (state: any) => state?.location?.location,
  );
  const [salesCycleReports, setSalesCycleReports] =
    useState<ClientTetherSalesCycleReport>(null);
  const [fetchLoading, setFetchLoading] = useState<boolean>(false);
  const [pipelinezItems, setPipelinezItems] = useState<
    PipelinezDashboardItem[]
  >([
    {
      value: 0,
      label: 'Lifetime Value',
      isCurrency: true,
      isPercentage: false,
      icon: <AttachMoneyOutlinedIcon sx={{ fontSize: '30px' }} />,
    },
    {
      value: 0,
      label: 'Commission',
      isCurrency: true,
      isPercentage: false,
      icon: <PiHandCoins size={30} />,
    },
    {
      value: 0,
      label: 'Spend',
      isCurrency: true,
      isPercentage: false,
      icon: <TbCreditCardPay size={30} />,
    },
    {
      value: 0,
      label: 'Leads',
      isCurrency: false,
      isPercentage: false,
      icon: <GroupAddOutlinedIcon sx={{ fontSize: '30px' }} />,
    },
    {
      value: 0,
      label: 'ROI',
      isCurrency: false,
      isPercentage: true,
      icon: <CurrencyExchangeOutlinedIcon sx={{ fontSize: '30px' }} />,
    },
    {
      value: 0,
      label: 'Cost-per-Sale',
      isCurrency: true,
      isPercentage: false,
      icon: <BsGraphUpArrow size={30} />,
    },
  ]);
  const [selectedLead, setSelectedLead] = useState<AdLead>(null);
  const [openLeadDetails, setOpenLeadDetails] = useState<boolean>(false);
  const [showByAds, setShowByAds] = useState<boolean>(false);
  const [expandedKeys, setExpandedKeys] = useState<string[]>([]);
  const [selectedAdId, setSelectedAdId] = useState<string>(null);
  const [openAdDetails, setOpenAdDetails] = useState<boolean>(false);
  const [commissionMode, setCommissionMode] = useState<boolean>(false);
  const [updateLeadLoading, setUpdateLeadLoading] = useState<boolean>(false);
  const [syncLoading, setSyncLoading] = useState<boolean>(false);
  const [crmSettings, setCrmSettings] = useState<CrmSetting>(null);

  useEffect(() => {
    if (location || brand) getCrmSettings();
  }, [location, brand]);

  useEffect(() => {
    if ((location || brand) && campaignProvider)
      if (showByAds) {
        getSalesCycleReportByAds();
      } else {
        getSalesCycleReport();
      }
  }, [location, brand, showByAds, campaignProvider]);

  const getSalesCycleReport = async () => {
    try {
      setFetchLoading(true);

      const response = await fetchSalesCycleReport(
        (location || brand)?._id,
        campaignProvider,
      );

      setSalesCycleReports(response);
      let tempDashboardItems: PipelinezDashboardItem[] = [];
      pipelinezItems.forEach((item: PipelinezDashboardItem) => {
        item.value = getDashboardItemValue(item.label, response);

        tempDashboardItems = [...tempDashboardItems, item];
      });

      setPipelinezItems(tempDashboardItems);
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const getSalesCycleReportByAds = async () => {
    try {
      setFetchLoading(true);
      const response = await fetchSalesCycleReportByAds(
        (location || brand)?._id,
        campaignProvider,
      );

      setSalesCycleReports(response);
      let tempDashboardItems: PipelinezDashboardItem[] = [];
      pipelinezItems.forEach((item: PipelinezDashboardItem) => {
        item.value = getDashboardItemValue(item.label, response);

        tempDashboardItems = [...tempDashboardItems, item];
      });

      setPipelinezItems(tempDashboardItems);
    } catch (error: any) {
      console.log(error);
    } finally {
      setFetchLoading(false);
    }
  };

  const getCrmSettings = async () => {
    try {
      const response = await getCrmSettingsByBrand((location || brand)?._id);

      setCrmSettings(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnSyncClientTetherClients = async () => {
    try {
      setSyncLoading(true);

      const response = await syncClientTetherClients(
        (location || brand)?._id,
        crmSettings?.clientTetherSettings?.accessToken,
        crmSettings?.clientTetherSettings?.webKey,
        'sales_cycle',
      );

      console.log(response);

      dispatch(
        toggleAlert({
          toggle: true,
          message:
            response.data.length === 0
              ? 'No recent changes in the Sales Cycle'
              : `Synced ${response.data.length} leads successfully`,
        }),
      );

      if (showByAds) {
        getSalesCycleReportByAds();
      } else {
        getSalesCycleReport();
      }
    } catch (error: any) {
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setSyncLoading(false);
    }
  };

  const handleOpenLeadDetails = (lead: AdLead) => {
    setSelectedLead(lead);
    setOpenLeadDetails(true);
  };

  const handleCloseLeadDetails = () => {
    setSelectedLead(null);
    setOpenLeadDetails(false);
  };

  const handleChangeTableView = () => {
    setShowByAds((prev) => !prev);
    setExpandedKeys([]);
  };

  const getDashboardItemValue = (
    label: any,
    report: ClientTetherSalesCycleReport,
  ) => {
    if (label === 'Lifetime Value') return report.totalValue;

    if (label === 'Spend') return report.totalSpend;

    if (label === 'Leads') return report.totalLeads;

    if (label === 'ROI') return report.roi >= 0 ? report.roi : 0;

    if (label === 'Commission') return report.totalCommission;

    return report.totalSales > 0 ? report.totalSpend / report.totalSales : 0;
  };

  const handleCloseAdDetails = () => {
    setOpenAdDetails(false);
    setSelectedAdId(null);
  };

  const handleOnUpdateLead = async (
    leadId: string,
    brandId: string,
    formValues: AdLead,
  ) => {
    try {
      setUpdateLeadLoading(true);

      const response = await editLeads(leadId, brandId, formValues);

      let tempReport: ClientTetherSalesCycleReport = { ...salesCycleReports };
      let temp: ClientTetherSalesCycleReportData[] = [];
      let currentTotalSales: number = salesCycleReports.totalSales;
      let currentTotalValue: number = salesCycleReports.totalValue;
      let currentTotalLeads: number = salesCycleReports.totalLeads;
      let currentTotalCommission: number = salesCycleReports.totalCommission;

      salesCycleReports?.data?.forEach(
        (data: ClientTetherSalesCycleReportData) => {
          if (
            data.id.split('-')[0] === response.data.id &&
            data.level === 'lead'
          ) {
            // Re-calculate Leads
            const oldTotalLeads = data.leads;
            currentTotalLeads -= oldTotalLeads;
            data.lead = response.data;
            data.leads = response.data.units;
            currentTotalLeads += data.leads;

            // Re-calculate Sales
            if (data.finalStage) {
              currentTotalSales -= oldTotalLeads;
              currentTotalSales += data.leads;
            }

            // Re-calculate Value
            const oldValue = data.value;
            currentTotalValue -= oldValue;
            const valuePerUnit =
              response.data.lifetimeValue * (data.closeRatio / 100);
            const newTotalValue = valuePerUnit * response.data.units;
            data.value = newTotalValue;
            currentTotalValue += newTotalValue;

            // Re-calculate Commission
            const oldCommission = data.commission;
            currentTotalCommission -= oldCommission;
            const commissionPerUnit =
              response.data.commission * (data.closeRatio / 100);
            const newTotalCommission = commissionPerUnit * response.data.units;
            currentTotalCommission += newTotalCommission;
            data.commission = newTotalCommission;

            if (showByAds) {
              const adLevelIndex = salesCycleReports.data.findIndex(
                (o: ClientTetherSalesCycleReportData) =>
                  o.id === `${data.id.split('-')[1]}`,
              );
              const salesCycleIndex = salesCycleReports.data.findIndex(
                (o: ClientTetherSalesCycleReportData) => {
                  const idParts = data.id.split('-');
                  return o.id === `${idParts[2]}-${idParts[1]}`;
                },
              );

              const currentSalesCycleValue =
                tempReport.data[salesCycleIndex].value;
              const currentSalesCycleLeads =
                tempReport.data[salesCycleIndex].leads;
              const salesCycleValue =
                currentSalesCycleValue - oldValue + newTotalValue;
              const salesCycleLeads =
                currentSalesCycleLeads - oldTotalLeads + response.data.units;
              tempReport.data[salesCycleIndex].value = salesCycleValue;
              tempReport.data[salesCycleIndex].leads = salesCycleLeads;

              const currentAdValue = tempReport.data[adLevelIndex].value;
              const currentAdLeads = tempReport.data[adLevelIndex].leads;
              const adValue = currentAdValue - oldValue + newTotalValue;
              const adLeads =
                currentAdLeads - oldTotalLeads + response.data.units;
              tempReport.data[adLevelIndex].value = adValue;
              tempReport.data[adLevelIndex].leads = adLeads;
            } else {
              const index = salesCycleReports.data.findIndex(
                (o: ClientTetherSalesCycleReportData) =>
                  o.id === data.id.split('-')[1],
              );

              const currentSalesCycleValue = tempReport.data[index].value;
              const currentSalesCycleLeads = tempReport.data[index].leads;
              const currentSalesCycleCommission =
                tempReport.data[index].commission;
              const salesCycleValue =
                currentSalesCycleValue - oldValue + newTotalValue;
              const salesCycleCommission =
                currentSalesCycleCommission -
                oldCommission +
                newTotalCommission;
              const salesCycleLeads =
                currentSalesCycleLeads - oldTotalLeads + response.data.units;
              tempReport.data[index].value = salesCycleValue;
              tempReport.data[index].leads = salesCycleLeads;
              tempReport.data[index].commission = salesCycleCommission;
            }
          }

          temp = [...temp, data];
        },
      );

      tempReport = {
        ...tempReport,
        data: temp,
        totalValue: currentTotalValue,
        totalLeads: currentTotalLeads,
        totalCommission: currentTotalCommission,
        totalSales: currentTotalSales,
        roi:
          ((currentTotalValue - salesCycleReports.totalSpend) /
            salesCycleReports.totalSpend) *
          100,
      };

      setSalesCycleReports(tempReport);

      let tempDashboardItems: PipelinezDashboardItem[] = [];
      pipelinezItems.forEach((item: PipelinezDashboardItem) => {
        item.value = getDashboardItemValue(item.label, tempReport);

        tempDashboardItems = [...tempDashboardItems, item];
      });

      setPipelinezItems(tempDashboardItems);

      dispatch(
        toggleAlert({
          toggle: true,
          message: `Lead Updated Successfully`,
        }),
      );
    } catch (error: any) {
      console.log(error);
      const errorMsg = errorMessageParser(error);
      dispatch(toggleAlert({ toggle: true, message: errorMsg, type: 'error' }));
    } finally {
      setUpdateLeadLoading(false);
    }
  };

  return (
    <div className={styles.report}>
      <div className={styles.base}>
        <div className={styles.header}>
          <PageHeader
            title="Pipelinez"
            marginBottom={showByAds ? '24px' : '0px'}
          />
        </div>

        {!showByAds ? (
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              sx={{ justifyContent: 'center', display: 'flex' }}
            >
              <Box
                component="div"
                sx={{
                  width: '200px',
                  height: '20px',
                  cursor: 'pointer',
                }}
                onClick={() => setCommissionMode((prev) => !prev)}
              />
            </Grid>
          </Grid>
        ) : null}

        <Grid container spacing={1} justifyContent="center" columns={12} mb={2}>
          {pipelinezItems.map((item: PipelinezDashboardItem, index: number) => {
            if (!commissionMode && item.label === 'Commission')
              return <Box key={`pipeline-item-${index + 1}`} />;

            if (commissionMode && item.label === 'Lifetime Value')
              return <Box key={`pipeline-item-${index + 1}`} />;

            return (
              <Grid item xs={12} sm={'auto'} key={`pipeline-item-${index + 1}`}>
                <DashboardItem
                  endingValue={item.value}
                  label={item.label}
                  isMobile={xsOnly}
                  icon={item.icon}
                  isCurrency={item.isCurrency}
                  isPercentage={item.isPercentage}
                  decimals={0}
                />
              </Grid>
            );
          })}
        </Grid>

        <Grid container spacing={1} mb={1}>
          <Grid
            item
            xs={12}
            sx={{ justifyContent: 'flex-end', display: 'flex' }}
          >
            {crmSettings?.clientTetherSettings?.accessToken &&
            crmSettings?.clientTetherSettings?.webKey ? (
              <PrimaryButton
                title="Sync Leads"
                type="button"
                handleOnClick={handleOnSyncClientTetherClients}
                marginRight5
                startIcon={<MdSync />}
                loading={syncLoading}
              />
            ) : null}

            <PrimaryButton
              title={`Group By ${showByAds ? 'Stage' : 'Ads'}`}
              type="button"
              handleOnClick={handleChangeTableView}
            />
          </Grid>
        </Grid>

        <ClientTetherSalesCycleReportTable
          data={salesCycleReports}
          loading={fetchLoading}
          onOpenLeadDetails={handleOpenLeadDetails}
          isByAds={showByAds}
          expandedKeys={expandedKeys}
          setExpandedKeys={setExpandedKeys}
          setSelectedAdId={setSelectedAdId}
          setOpenAdDetails={setOpenAdDetails}
          commissionMode={commissionMode}
        />
      </div>

      <DrawerMenu
        anchor="right"
        open={openLeadDetails}
        onClose={handleCloseLeadDetails}
      >
        <LeadDetails
          lead={selectedLead}
          onClose={handleCloseLeadDetails}
          crm="client-tether"
          brand={location || brand}
          onUpdate={handleOnUpdateLead}
          updateLoading={updateLeadLoading}
          commissionMode={commissionMode}
        />
      </DrawerMenu>

      <DrawerMenu
        anchor="right"
        open={openAdDetails}
        onClose={handleCloseAdDetails}
      >
        <FacebookAdDetails
          brand={location || brand}
          adId={selectedAdId}
          onClose={handleCloseAdDetails}
          isMobile={xsOnly}
        />
      </DrawerMenu>
    </div>
  );
};

export default Pipelinez;
