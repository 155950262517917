import {
  Box,
  Button,
  TextField,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { DEFAULT_COLOR_THEME } from '../../../../utils';

interface GoogleTemplateSummaryProps {
  template: any;
  ad?: any;
  templateType?: string;
}

const GoogleTemplateSummary: React.FC<GoogleTemplateSummaryProps> = ({
  template,
  ad = null,
  templateType = 'search',
}) => {
  console.log('template', template);
  const [websiteExpanded, setWebsiteExpanded] = useState(false);
  const [keywordsExpanded, setKeywordsExpanded] = useState(false);
  useEffect(() => {
    console.log('ad', ad);
    console.log('template', template);
  }, []);
  return (
    <>
      <Typography sx={{ fontWeight: 600, fontSize: `1.2rem` }}>
        Summary:
      </Typography>
      <Box sx={{ marginBottom: '10px' }}>
        {ad ? (
          <>
            <Typography
              sx={{
                fontSize: '1rem',
                fontWeight: 'bold',
                color: DEFAULT_COLOR_THEME,
              }}
            >
              Budget:
            </Typography>
            <Typography
              sx={{
                fontSize: '1rem',
                color: '#000000',
              }}
            >
              ${ad.budget}
            </Typography>

            <Typography
              sx={{
                fontSize: '1rem',
                fontWeight: 'bold',
                color: DEFAULT_COLOR_THEME,
              }}
            >
              Location Set:
            </Typography>
            <Typography
              sx={{
                fontSize: '1rem',
                color: '#000000',
              }}
            >
              {ad.locationSet?.name} - {ad.locationSet?.locations.length}{' '}
              Locations
            </Typography>
            <Typography
              sx={{
                fontSize: '0.8rem',
                color: '#000000',
                fontStyle: `italic`,
              }}
            >
              {ad.locationSet?.locations
                ?.map((loc: any) => loc.name)
                .join(', ')}
            </Typography>
          </>
        ) : null}
      </Box>

      {template?.keywords && template?.keywords?.length > 0 ? (
        <Box>
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 'bold',
              color: DEFAULT_COLOR_THEME,
            }}
          >
            Keywords:
          </Typography>
          {template.keywords ? (
            <Box sx={{ paddingLeft: `10px` }}>
              {template.keywords?.map((key: any) => (
                <Box>
                  •{' '}
                  <Typography
                    sx={{
                      fontWeight: '200',
                      display: 'inline',
                      fontStyle: 'italic',
                    }}
                  >
                    {`${key.exact ? `EXACT | ` : ''}`}
                    {`${key.phrase ? `PHRASE | ` : ''}`}
                    {`${key.broad ? `BROAD | ` : ''}`}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: '600',
                      display: 'inline',
                    }}
                  >{`${key.keyword}`}</Typography>
                </Box>
              ))}
            </Box>
          ) : null}
        </Box>
      ) : null}

      {template?.display_keywords && template?.display_keywords?.length > 0 ? (
        <Box>
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 'bold',
              color: DEFAULT_COLOR_THEME,
            }}
          >
            Keywords:
          </Typography>
          {template.display_keywords ? (
            <Box sx={{ paddingLeft: `10px`, marginBottom: '10px' }}>
              {template.display_keywords?.map((key: any, i: number) => (
                <Box>
                  {keywordsExpanded || i < 5 ? (
                    <Typography
                      sx={{
                        fontWeight: '400',
                        display: 'inline',
                      }}
                    >
                      • {`${key}`}
                    </Typography>
                  ) : null}
                </Box>
              ))}
              {template.display_keywords.length > 5 ? (
                <Button
                  sx={{
                    fontWeight: '400',
                    display: keywordsExpanded ? 'none' : 'inline',
                  }}
                  onClick={() => setKeywordsExpanded(true)}
                >
                  + {template.display_keywords.length - 5} more
                </Button>
              ) : null}
            </Box>
          ) : null}
        </Box>
      ) : null}

      {template?.websites && template?.websites?.length > 0 ? (
        <Box>
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 'bold',
              color: DEFAULT_COLOR_THEME,
            }}
          >
            Websites:
          </Typography>
          {template.websites ? (
            <Box sx={{ paddingLeft: `10px`, marginBottom: '10px' }}>
              {template.websites?.map((w: any, i: number) => (
                <Box>
                  {websiteExpanded || i < 5 ? (
                    <Typography
                      sx={{
                        fontWeight: '400',
                        display: 'inline',
                      }}
                    >
                      • {`${w}`}
                    </Typography>
                  ) : null}
                </Box>
              ))}
              {template.websites.length > 5 ? (
                <Button
                  sx={{
                    fontWeight: '400',
                    display: websiteExpanded ? 'none' : 'inline',
                  }}
                  onClick={() => setWebsiteExpanded(true)}
                >
                  + {template.websites.length - 5} more
                </Button>
              ) : null}
            </Box>
          ) : null}
        </Box>
      ) : null}

      {/* PHONE NUMBER */}
      {template?.phone_number && template?.phone_number?.length > 0 ? (
        <Box>
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 'bold',
              color: DEFAULT_COLOR_THEME,
              marginBottom: '10px',
            }}
          >
            Phone Number:{` `}
            <Typography
              sx={{
                fontWeight: '200',
                display: 'inline',
              }}
            >
              {template.phone_number}
            </Typography>
          </Typography>
        </Box>
      ) : null}

      {/* FINAL URL */}
      {template?.final_url && template?.final_url?.length > 0 ? (
        <Box>
          <Box
            sx={{
              fontSize: '1rem',
              fontWeight: 'bold',
              color: DEFAULT_COLOR_THEME,
            }}
          >
            Final URL:{` `}
          </Box>
          <Box
            sx={{
              fontWeight: '200',
              paddingLeft: '10px',
              marginBottom: '10px',
            }}
          >
            <Typography>{template.final_url}</Typography>
          </Box>
        </Box>
      ) : null}

      {/* HEADLINES */}
      {template?.headlines && template?.headlines?.length > 0 ? (
        <Box
          sx={{
            marginBottom: '10px',
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: '1rem',
                fontWeight: 'bold',
                color: DEFAULT_COLOR_THEME,
              }}
            >
              Headlines:
            </Typography>
          </Box>

          <Box sx={{ paddingLeft: `10px` }}>
            {template.headlines.map((h: string) => (
              <Box>• {h}</Box>
            ))}
          </Box>
        </Box>
      ) : null}
      {/* DESCRIPTIONS */}
      {template?.descriptions && template?.descriptions?.length > 0 ? (
        <Box
          sx={{
            marginBottom: '10px',
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: '1rem',
                fontWeight: 'bold',
                color: DEFAULT_COLOR_THEME,
              }}
            >
              Descriptions:
            </Typography>
          </Box>

          <Box sx={{ paddingLeft: `10px` }}>
            {template.descriptions.map((d: string) => (
              <Box>• {d}</Box>
            ))}
          </Box>
        </Box>
      ) : null}
      {/* CALLOUTS */}
      {template?.callouts && template?.callouts?.length > 0 ? (
        <Box
          sx={{
            marginBottom: '10px',
          }}
        >
          <Box>
            <Typography
              sx={{
                fontSize: '1rem',
                fontWeight: 'bold',
                color: DEFAULT_COLOR_THEME,
              }}
            >
              Callouts:
            </Typography>
          </Box>

          <Box sx={{ paddingLeft: `10px` }}>
            {template.callouts.map((c: string) => (
              <Box>• {c}</Box>
            ))}
          </Box>
        </Box>
      ) : null}

      {/* SITELINKS */}
      {template?.sitelinks && template?.sitelinks?.length > 0 ? (
        <Box>
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 'bold',
              color: DEFAULT_COLOR_THEME,
              marginBottom: '10px',
            }}
          >
            Site Links
            <Box
              sx={{
                fontWeight: '200',
                display: 'inline',
                paddingLeft: `10px`,
              }}
            >
              {template.sitelinks.map((s: any) => (
                <Box
                  sx={{
                    fontSize: '1rem',
                    color: `#000000`,
                  }}
                >
                  • {s.headline} - {s.description1} - {s.description2} -{' '}
                  {s.finalUrl}
                </Box>
              ))}
            </Box>
          </Typography>
        </Box>
      ) : null}

      {/* demographics */}
      {template?.demographics &&
      Object.keys(template?.demographics).length > 0 ? (
        <Box>
          <Box
            sx={{
              fontSize: '1rem',
              fontWeight: 'bold',
              color: DEFAULT_COLOR_THEME,
            }}
          >
            Demographics:{` `}
          </Box>
          <Box
            sx={{
              fontWeight: '200',
              paddingLeft: '10px',
              marginBottom: '10px',
            }}
          >
            <Typography>Ages:</Typography>
            {template.demographics?.ages?.map((a: any) => (
              <Typography sx={{ paddingLeft: 1 }}>• {a}</Typography>
            ))}
            <Typography>Gender:</Typography>
            {template.demographics?.genders?.map((g: any) => (
              <Typography sx={{ paddingLeft: 1 }}>• {g}</Typography>
            ))}
            <Typography>Income Range:</Typography>
            {template.demographics?.incomeRanges?.map((i: any) => (
              <Typography sx={{ paddingLeft: 1 }}>• {i}</Typography>
            ))}
            <Typography>Parental Status:</Typography>
            {template.demographics?.parents?.map((p: any) => (
              <Typography sx={{ paddingLeft: 1 }}>• {p}</Typography>
            ))}
          </Box>
        </Box>
      ) : null}

      {/* demographics */}
      {template?.topics_and_interest &&
      Object.keys(template?.topics_and_interest).length > 0 ? (
        <Box>
          <Box
            sx={{
              fontSize: '1rem',
              fontWeight: 'bold',
              color: DEFAULT_COLOR_THEME,
            }}
          >
            Topics And Interests:{` `}
          </Box>
          <Box
            sx={{
              fontWeight: '200',
              paddingLeft: '10px',
              marginBottom: '10px',
            }}
          >
            <Typography>Topics:</Typography>
            {template.topics_and_interest?.topics?.map((t: any) => (
              <Typography sx={{ paddingLeft: 1 }}>• {t.name}</Typography>
            ))}
            <Typography>Interests:</Typography>
            {template.topics_and_interest?.interests?.map((t: any) => (
              <Typography sx={{ paddingLeft: 1 }}>• {t.name}</Typography>
            ))}
            <Typography>Life Events:</Typography>
            {template.topics_and_interest?.lifeEvents?.map((t: any) => (
              <Typography sx={{ paddingLeft: 1 }}>• {t.name}</Typography>
            ))}
            <Typography>Custom Affinity:</Typography>
            {template.topics_and_interest?.customAffinity?.map((t: any) => (
              <Typography sx={{ paddingLeft: 1 }}>• {t}</Typography>
            ))}
            <Typography>Custom Interests:</Typography>
            {template.topics_and_interest?.customInterests?.map((t: any) => (
              <Typography sx={{ paddingLeft: 1 }}>• {t.name}</Typography>
            ))}
            <Typography>Custom Audiences:</Typography>
            {template.topics_and_interest?.customAudiences?.map((t: any) => (
              <Typography sx={{ paddingLeft: 1 }}>• {t.name}</Typography>
            ))}
          </Box>
        </Box>
      ) : null}

      {/* BUSINESS LOGO  */}
      <Box>
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 'bold',
            color: DEFAULT_COLOR_THEME,
            marginBottom: '10px',
          }}
        >
          Business Logo
        </Typography>

        <Box
          sx={{
            display: `flex`,
            flexWrap: `wrap`,
            gap: `0.5rem`,
          }}
        >
          {template.business_logo?.url ? (
            <Box
              sx={{
                width: '60px',
                height: '60px',
                borderRadius: '100%',
                border: `1px solid #333333`,
                padding: 0,
                overflow: 'hidden',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <img
                src={template.business_logo?.url}
                alt={template.business_logo?.url}
                style={{
                  width: '50px',
                  height: 'auto',
                }}
              />
            </Box>
          ) : (
            <Typography sx={{ paddingLeft: `10px`, fontWeight: 200 }}>
              No business logo provided
            </Typography>
          )}
        </Box>
      </Box>

      {/* IMAGES */}
      <Box>
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 'bold',
            color: DEFAULT_COLOR_THEME,
            marginBottom: '10px',
          }}
        >
          Images
        </Typography>

        <Box
          sx={{
            display: `flex`,
            flexWrap: `wrap`,
            gap: `0.5rem`,
          }}
        >
          {template.images?.map((img: any) => (
            <Box
              sx={{
                width: '100px',
              }}
            >
              <img
                src={img.url}
                alt={img.url}
                style={{
                  width: '100%',
                  height: 'auto',
                }}
              />
            </Box>
          ))}
        </Box>
      </Box>

      {/* VIDEOS */}
      {template?.videos && template?.videos?.length > 0 ? (
        <Box>
          <Typography
            sx={{
              fontSize: '1rem',
              fontWeight: 'bold',
              color: DEFAULT_COLOR_THEME,
              marginBottom: '10px',
            }}
          >
            Videos
          </Typography>

          <Box
            sx={{
              display: `flex`,
              flexWrap: `wrap`,
              gap: `0.5rem`,
            }}
          >
            {template?.videos?.map((vid: any) => (
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  backgroundColor: '#f5f5f5',
                  padding: '5px',
                  borderRadius: '5px',
                  gap: 1,
                }}
              >
                <Box
                  sx={{
                    width: '100px',
                    flexShrink: 0,
                  }}
                >
                  <img
                    src={vid.image}
                    alt={vid.title}
                    style={{
                      width: '100%',
                      height: 'auto',
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 1,
                  }}
                >
                  <Box sx={{ fontSize: '1rem', fontWeight: 600 }}>
                    {vid.title}
                  </Box>
                  <Box
                    sx={{
                      fontSize: '0.8rem',
                      fontWeight: 200,
                      display: '-webkit-box',
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: 'vertical',
                      overflow: 'hidden',
                    }}
                  >
                    {vid.description}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      ) : null}
    </>
  );
};

export default GoogleTemplateSummary;
