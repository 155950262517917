import {
  Box,
  Chip,
  FormControl,
  FormControlLabel,
  FormLabel,
  Typography,
  FormGroup,
  Checkbox,
  Button,
  List,
  ListItem,
  ListItemText,
  CircularProgress as Spinner,
  Breadcrumbs,
  Stack,
} from '@mui/material';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useEffect, useState } from 'react';
import { PrimaryButton } from '../Buttons';
import { useDispatch } from 'react-redux';
import { toggleAlert } from '../../redux/actions';
import PopupModal from '../Modal';
import {
  modifyGoogleResourceStatus,
  setGoogleKeywords,
} from '../../services/googleAds';
import {
  MdOutlinePlayCircle,
  MdOutlinePauseCircleOutline,
  MdOutlineCancel,
  MdEdit,
  MdOutlineSave,
} from 'react-icons/md';

interface GoogleDirectDrawerProps {
  termsList: {
    id: number;
    text: string;
    matchtype: string;
    resource_name: string;
    ad_group_resource_name: string;
    status: string;
  }[];
  termType: string;
  campaignLock: string;
  adGroupLock: string;
  adGroupName: string;
  uncheckAllTerms: () => void;
  updateRows: (rows: any[], status: string) => void;
  brandId: string;
  removeFromTermList: (row: any) => void;
}

const GoogleDirectDrawer: React.FC<GoogleDirectDrawerProps> = ({
  termsList,
  termType,
  campaignLock,
  adGroupName,
  uncheckAllTerms,
  updateRows,
  brandId,
  removeFromTermList,
}) => {
  const [newMatchType, setNewMatchType] = useState<string[]>([]);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [confirmModal, setConfirmModal] = useState<{
    show: boolean;
    action: string;
  }>({
    show: false,
    action: '',
  });

  const [statusModal, setStatusModal] = useState<boolean>(false);
  const [statusModalAction, setStatusModalAction] = useState<string>('');
  const [editMode, setEditMode] = useState<boolean>(false);

  useEffect(() => {
    if (termsList.length <= 0) setNewMatchType([]);
  }, [termsList]);

  const dispatch = useDispatch();

  /**
   * Executes keyword changes by sending a POST request to the specified API endpoint.
   *
   * @param params - The parameters for the keyword changes.
   * @param params.brandId - The ID of the brand.
   * @param params.matchType - The type of match for the keywords.
   * @param params.keyword - An array of keyword objects.
   * @param params.keyword[].match_type - The match type of the keyword.
   * @param params.keyword[].text - The text of the keyword.
   * @param params.keyword[].resource_name - The resource name of the keyword.
   * @param params.keyword[].ad_group_resource_name - The ad group resource name of the keyword.
   * @param params.keyword[].negative - Optional flag indicating if the keyword is negative.
   *
   * @returns A promise that resolves when the keyword changes have been executed.
   */
  const executeKeywordChanges = async (params: {
    brandId: string;
    matchType: string[];
    keyword: {
      match_type: string;
      text: string;
      resource_name: string;
      ad_group_resource_name: string;
      negative?: boolean | undefined;
    }[];
  }) => {
    setIsSaving(true);
    const newkeywords = await setGoogleKeywords(params);

    setConfirmModal({ show: false, action: '' });
    setIsSaving(false);
    dispatch(
      toggleAlert({ toggle: true, type: 'success', message: 'Keywords saved' }),
    );
    //updateRows();
  };

  /**
   * Handles the change of match type for the terms list.
   * Logs the terms list to the console and prepares the keyword data for execution.
   *
   * @async
   * @function handleMatchTypeChange
   *
   * @returns {Promise<void>} A promise that resolves when the match type change has been handled.
   *
   * @remarks
   * This function maps over the `termsList` to create a new array of keyword objects,
   * each containing properties such as `row_id`, `match_type`, `text`, `resource_name`,
   * and `ad_group_resource_name`. It then calls `executeKeywordChanges` with the
   * `brandId`, `matchType`, and the newly created `keyword` array.
   */

  const handleConfirmChangesModal = (action: string) => {
    if (action === 'saveKeywords') {
      handleMatchTypeChange();
    }
    if (action === 'saveNegativeKeywords') {
      handleAddAsNegativeKeyword();
    }
  };

  const handleMatchTypeChange = async () => {
    const matchType = newMatchType,
      keyword = termsList.map((k) => {
        return {
          row_id: k.id,
          match_type: k.matchtype || '',
          text: k.text || '',
          resource_name: k.resource_name || '',
          ad_group_resource_name: k.ad_group_resource_name || '',
        };
      });
    executeKeywordChanges({
      brandId,
      matchType,
      keyword,
    });
  };

  const handleAddAsNegativeKeyword = async () => {
    const matchType = newMatchType,
      keyword = termsList.map((k) => {
        return {
          match_type: k.matchtype,
          text: k.text,
          resource_name: k.resource_name,
          ad_group_resource_name: k.ad_group_resource_name,
          negative: true,
        };
      });
    executeKeywordChanges({
      brandId,
      matchType,
      keyword,
    });
  };

  const changeMatchType = (target: 'BROAD' | 'EXACT' | 'PHRASE') => {
    if (newMatchType.includes(target)) {
      setNewMatchType(newMatchType.filter((k) => k !== target));
    } else {
      setNewMatchType([...newMatchType, target]);
    }
  };

  return (
    <>
      {statusModal ? (
        <PopupModal
          open={statusModal}
          handleClose={() => setStatusModal(false)}
        >
          {isSaving ? (
            <Box>
              <Spinner />
            </Box>
          ) : (
            <>
              <Box
                minHeight={300}
                maxHeight={500}
                overflow={`hidden`}
                position={`relative`}
              >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  The following keywords will be {statusModalAction}: :
                </Typography>
                <Box
                  minHeight={100}
                  maxHeight={400}
                  marginBottom={`150px`}
                  sx={{
                    overflowY: 'auto',
                  }}
                >
                  <List
                    sx={{
                      backgroundColor: '#e3e3e3',
                      width: `100%`,
                      mx: `auto`,
                      borderRadius: `10px`,
                    }}
                  >
                    {termsList.map((term) => (
                      <ListItem
                        key={term.id}
                        sx={{ margin: 0, paddingX: `10px`, paddingY: 0 }}
                      >
                        <ListItemText
                          sx={{
                            backgroundColor: '#fbfbfbaa',
                            paddingX: '10px',
                            paddingY: '4px',
                            borderRadius: '10px',
                          }}
                          primary={`${term.text}`}
                          secondary={
                            term.status === statusModalAction
                              ? statusModalAction
                              : `${term.status} => ${statusModalAction}`
                          }
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>

              <Box
                sx={{
                  position: 'fixed',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  padding: '20px 10px',
                }}
              >
                <Typography
                  id="modal-modal-description"
                  sx={{ mb: 2, px: 4, textAlign: `center` }}
                >
                  {`This action cannot be undone.`}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      setIsSaving(true);
                      modifyGoogleResourceStatus({
                        brandId: brandId,
                        resource_names: termsList.map(
                          (term) => term.resource_name,
                        ),
                        type: 'keyword',
                        status: statusModalAction,
                      }).then(() => {
                        setIsSaving(false);
                        setStatusModal(false);
                        updateRows(
                          termsList.map((term) => term.id),
                          statusModalAction,
                        );
                      });
                    }}
                  >
                    Continue
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => setStatusModal(false)}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </PopupModal>
      ) : null}

      {confirmModal.show ? (
        <PopupModal
          open={confirmModal.show}
          handleClose={() => setConfirmModal({ show: false, action: '' })}
        >
          {isSaving ? (
            <Box>
              <Spinner />
            </Box>
          ) : (
            <>
              <Box
                minHeight={300}
                maxHeight={500}
                overflow={`hidden`}
                position={`relative`}
              >
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  The following will be added as{' '}
                  {confirmModal.action === 'saveKeywords'
                    ? 'Keywords'
                    : 'Negative Keywords'}
                  :
                </Typography>
                <Box
                  minHeight={100}
                  maxHeight={400}
                  marginBottom={`150px`}
                  sx={{
                    overflowY: 'auto',
                  }}
                >
                  <List
                    sx={{
                      backgroundColor: '#e3e3e3',
                      width: `fit-content`,
                      mx: `auto`,
                      borderRadius: `10px`,
                    }}
                  >
                    {termsList.map((term) => (
                      <ListItem key={term.id}>
                        <ListItemText
                          sx={{
                            backgroundColor: '#fbfbfbaa',
                            padding: '10px',
                            borderRadius: '10px',
                          }}
                          primary={`${term.text} (${term.matchtype})`}
                          secondary={`${
                            !newMatchType.includes(term.matchtype)
                              ? `will be changed to (${newMatchType
                                  .filter((k) => k !== term.matchtype)
                                  .join(', ')})`
                              : newMatchType.filter((k) => k !== term.matchtype)
                                  .length > 0
                              ? `will be added as (${newMatchType
                                  .filter((k) => k !== term.matchtype)
                                  .join(', ')})`
                              : ``
                          }
                    `}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Box>
              </Box>
              <Box
                sx={{
                  position: 'fixed',
                  bottom: 0,
                  left: 0,
                  width: '100%',
                  padding: '20px 10px',
                }}
              >
                <Typography
                  id="modal-modal-description"
                  sx={{ mb: 2, px: 4, textAlign: `center` }}
                >
                  {`This action cannot be undone.`}
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-around',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() =>
                      handleConfirmChangesModal(confirmModal.action)
                    }
                  >
                    Continue
                  </Button>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => setConfirmModal({ show: false, action: '' })}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </>
          )}
        </PopupModal>
      ) : null}

      <Box
        bgcolor={`#eee`}
        borderTop={`1px solid #ccc`}
        sx={{
          position: 'fixed',
          bottom: 0,
          left: 0,
          width: '100%',
          minHeight: '256px',
          maxHeight: '35vh',
          zIndex: 1000,
          paddingLeft: `240px`,
        }}
        overflow={`auto`}
        hidden={termsList ? (termsList.length <= 0 ? true : false) : true}
      >
        <Box padding={`10px`}>
          <Box
            display={`flex`}
            flexDirection={`row`}
            justifyContent={`space-between`}
            mb={`10px`}
          >
            <Stack spacing={2}>
              <Breadcrumbs
                separator={<NavigateNextIcon fontSize="small" />}
                aria-label="breadcrumb"
              >
                <Typography color={`#096f4d`} fontWeight={600}>
                  {campaignLock}
                </Typography>
                <Typography color={`#096f4d`} fontWeight={600}>
                  {adGroupName}
                </Typography>
                <Typography color={`#096f4d`} fontWeight={600}>
                  {termType}[{termsList ? termsList.length : 0}]
                </Typography>
              </Breadcrumbs>
            </Stack>
            <Chip
              label={`Clear All`}
              color="primary"
              onClick={uncheckAllTerms}
            />
          </Box>

          <Box
            borderRadius={`20px`}
            bgcolor={`#fff`}
            paddingX={`20px`}
            paddingY={`5px`}
            display={`flex`}
            flexWrap={`wrap`}
            gap={`5px`}
            maxHeight={`80px`}
            overflow={`auto`}
          >
            {termsList
              ? termsList.map((term) => (
                  <Chip
                    label={`${term.text} (${term.matchtype})`}
                    color="primary"
                    onDelete={() => removeFromTermList(term)}
                    key={term.id}
                    sx={{ fontSize: `0.7rem` }}
                  />
                ))
              : null}
          </Box>
          <Box
            sx={{
              position: 'relative',
              mt: 2,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'start',
              alignItems: 'center',
              width: '100%',
              gap: 4,
            }}
          >
            <Box
              sx={{
                position: 'relative',
                my: 2,
                border: '1px solid #aaa',
                borderRadius: '10px',
                padding: '20px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 4,
                minWidth: `200px`,
                minHeight: `85px`,
                transition: 'all 0.3s',
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '-10px',
                  left: `10px`,
                  padding: '0 5px',
                  backgroundColor: '#eee',
                  color: '#096f4d',
                  fontWeight: 600,
                }}
              >
                Change Match Type
              </Box>

              <Box
                sx={{
                  flexShrink: 0,
                }}
              >
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="BROAD"
                    control={
                      <Checkbox
                        checked={newMatchType.includes('BROAD')}
                        onChange={() => changeMatchType('BROAD')}
                      />
                    }
                    label="BROAD"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="EXACT"
                    control={
                      <Checkbox
                        checked={newMatchType.includes('EXACT')}
                        onChange={() => changeMatchType('EXACT')}
                      />
                    }
                    label="EXACT"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="PHRASE"
                    control={
                      <Checkbox
                        checked={newMatchType.includes('PHRASE')}
                        onChange={() => changeMatchType('PHRASE')}
                      />
                    }
                    label="PHRASE"
                    labelPlacement="end"
                  />
                </FormGroup>
              </Box>

              <Button variant="contained">
                <MdOutlineSave
                  style={{
                    fontSize: 20,
                    marginRight: 5,
                  }}
                />
                Save
              </Button>
            </Box>

            <Box
              sx={{
                position: 'relative',
                my: 2,
                border: '1px solid #aaa',
                borderRadius: '10px',
                padding: '20px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 4,
                minHeight: `85px`,
              }}
            >
              <Box
                sx={{
                  position: 'absolute',
                  top: '-10px',
                  left: `10px`,
                  padding: '0 5px',
                  backgroundColor: '#eee',
                  color: '#096f4d',
                  fontWeight: 600,
                }}
              >
                Status
              </Box>

              <Button
                variant="contained"
                onClick={() => {
                  setStatusModalAction('ENABLED');
                  setStatusModal(true);
                }}
              >
                <MdOutlinePlayCircle
                  style={{
                    fontSize: 20,
                    marginRight: 5,
                  }}
                />
                Enable
              </Button>

              <Button
                variant="contained"
                onClick={() => {
                  setStatusModalAction('PAUSED');
                  setStatusModal(true);
                }}
              >
                <MdOutlinePauseCircleOutline
                  style={{
                    fontSize: 20,
                    marginRight: 5,
                  }}
                />
                Pause
              </Button>

              <Button
                variant="contained"
                onClick={() => {
                  setStatusModalAction('REMOVED');
                  setStatusModal(true);
                }}
              >
                <MdOutlineCancel
                  style={{
                    fontSize: 20,
                    marginRight: 5,
                  }}
                />
                Remove
              </Button>
            </Box>
          </Box>
          {/* change match type */}
          <Box marginTop={`10px`} display={`none`}>
            <FormControl component="fieldset">
              <FormLabel
                component="legend"
                sx={{
                  fontWeight: 600,
                  color: `#096f4d`,
                }}
              >
                Change Match Type
              </FormLabel>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="BROAD"
                  control={
                    <Checkbox
                      checked={newMatchType.includes('BROAD')}
                      onChange={() => changeMatchType('BROAD')}
                    />
                  }
                  label="BROAD"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="EXACT"
                  control={
                    <Checkbox
                      checked={newMatchType.includes('EXACT')}
                      onChange={() => changeMatchType('EXACT')}
                    />
                  }
                  label="EXACT"
                  labelPlacement="end"
                />
                <FormControlLabel
                  value="PHRASE"
                  control={
                    <Checkbox
                      checked={newMatchType.includes('PHRASE')}
                      onChange={() => changeMatchType('PHRASE')}
                    />
                  }
                  label="PHRASE"
                  labelPlacement="end"
                />
              </FormGroup>
            </FormControl>

            {termType === 'Keywords' ? (
              <>
                <div
                  style={{ display: `flex`, gap: `10px`, alignItems: 'center' }}
                >
                  <PrimaryButton
                    title="Save Changes"
                    handleOnClick={() => {
                      if (newMatchType.length === 0) {
                        dispatch(
                          toggleAlert({
                            toggle: true,
                            type: 'error',
                            message: 'Please select a match type',
                          }),
                        );
                        return;
                      } else {
                        setConfirmModal({
                          show: true,
                          action: 'saveKeywords',
                        });
                      }
                    }}
                    type="button"
                    rounded
                  />
                </div>
              </>
            ) : (
              <>
                <Box display={`flex`} gap={`1.2rem`} marginTop={`0.5rem`}>
                  <PrimaryButton
                    title="Add as Keyword"
                    handleOnClick={() => {
                      if (newMatchType.length === 0) {
                        dispatch(
                          toggleAlert({
                            toggle: true,
                            type: 'error',
                            message: 'Please select a match type',
                          }),
                        );
                        return;
                      } else {
                        setConfirmModal({
                          show: true,
                          action: 'saveKeywords',
                        });
                      }
                    }}
                    type="button"
                    rounded
                  />
                  <PrimaryButton
                    title="Add as Negative Keyword"
                    handleOnClick={() => {
                      if (newMatchType.length === 0) {
                        dispatch(
                          toggleAlert({
                            toggle: true,
                            type: 'error',
                            message: 'Please select a match type',
                          }),
                        );
                        return;
                      } else {
                        setConfirmModal({
                          show: true,
                          action: 'saveNegativeKeywords',
                        });
                      }
                    }}
                    type="button"
                    rounded
                  />
                </Box>
              </>
            )}
          </Box>
          {/* change match type */}
        </Box>
      </Box>
    </>
  );
};

export default GoogleDirectDrawer;
